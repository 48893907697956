<template>
	<div>
		<v-data-table :items="map" :headers="headers"></v-data-table>
		<export :rows="map" :headers="exportColumns" name="data"></export>
	</div>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vuex from "vuex";
import Export from "@c/downloads/Export";
// Renders an exportable datatable of the entire assessment
// @group Results
export default {
	name: "ResultsTable",
	components: {
		Export,
	},
	computed: {
		...Vuex.mapState({
			goals: (state) => state.goals.data,
			goalGroups: (state) => state.goalGroups.data,
			departments: (state) => state.departments.data,
			sectionParts: (state) => state.sectionParts.data,
			responseData: (state) => state.responseData.data,
		}),
		questions() {
			return this.$store.getters["questions/defaultOrder"];
		},
		view() {
			return this.$store.getters.canReadView;
		},
		dataMap() {
			return this.$store.getters["responseData/map"];
		},
		map() {
			var self = this;
			if (!self.responseData || !self.dataMap) {
				return;
			}
			var data = [];

			self.view.forEach((item) => {
				var part = self.sectionParts[item.part];
				var department = self.departments[item.department];
				var goalGroup = self.goalGroups[part.goalGroup];
				var goal = self.goals[item.goal];
				var dataId = this.mwutilities.getByString(
					self.dataMap,
					`${item.department}.${item.part}.${item.goal}`
				);

				var obj = {
					section: department ? department.name : "",
					goalgroup: goalGroup ? goalGroup.name : "",
					goal: goal ? goal.name : "",
				};

				if (dataId) {
					var response = self.responseData[dataId];
					obj.comments = response.comments || "";
					Object.keys(response.data).forEach((q) => {
						obj[q] = this.mwutilities.prettyPrint(
							response.data[q],
							"SENTENCE"
						);
					});
				}
				data.push(obj);
			});

			return data;
		},
		headers() {
			var cols = [
				{ text: this.$t("sections.nav.single"), value: "section" },
				{ text: this.$t("goal_groups.nav.single"), value: "goalgroup" },
				{ text: this.$t("goals.nav.single"), value: "goal" },
			];

			var questions = this.questions;
			if (questions) {
				questions.forEach((q) => {
					cols.push({ value: q.id, text: q.title });
				});
			}
			cols.push({ text: this.$t("results.comments"), value: "comments" });
			return cols;
		},
		exportColumns() {
			var columns = this.headers;
			return columns.map((c) => {
				return { field: c.value, label: c.text };
			});
		},
	},
};
</script>
