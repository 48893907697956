<template>
	<div>
		<results-table></results-table>
	</div>
</template>

<style lang="less"></style>

<script type="text/javascript">
import ResultsTable from "@c/survey/results/ResultsTable";

export default {
	name: "Results",
	data: () => {
		return {
			name: "data",
		};
	},
	components: {
		ResultsTable,
	},
};
//
</script>
